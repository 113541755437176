<template>
  <b-modal
    :visible="visible"
    :title="$t('events.add-to-external-calendar.title')"
    footer-class="d-none"
    size="lg"
    @change="$emit('update:visible', $event)"
  >
    <b-list-group class="my-2 mx-1">
      <b-list-group-item target="_blank" :href="googleURL">
        <b-icon-google class="icon icon--google mr-50" />
        {{ $t('events.add-to-external-calendar.google') }}
      </b-list-group-item>
      <b-list-group-item target="_blank" :href="appleURL">
        <img src="@/assets/images/icons/brands/apple-icon.svg" class="icon icon--img mr-50">
        {{ $t('events.add-to-external-calendar.apple') }}
      </b-list-group-item>
      <b-list-group-item target="_blank" :href="outlookURL">
        <img src="@/assets/images/icons/brands/outlook-icon.svg" class="icon icon--img mr-50">
        {{ $t('events.add-to-external-calendar.outlook') }}
      </b-list-group-item>
    </b-list-group>
  </b-modal>
</template>

<script>
import {
  BModal, BListGroup, BListGroupItem, BIconGoogle,
} from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import calendarURLGenerator from '@/@core/utils/calendar/calendarURLGenerator';
import { getUri } from '@/@core/utils/url-utils';

export default {
  name: 'AddToExternalCalendarModal',
  components: {
    BModal,
    BListGroup,
    BListGroupItem,
    BIconGoogle,
  },
  model: {
    prop: 'visible',
    event: 'update:visible',
  },
  props: {
    visible: Boolean,
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    computedEventConfig() {
      const timezoneOffset = new Date().getTimezoneOffset(); // in minutes
      const endDate = this.event?.endDate
        || this.$moment(this.event?.startDate)
          .add(30, 'minutes')
          .valueOf();
      const { href } = this.$router.resolve({
        name: 'event',
        params: { id: this.event?.key, communityId: this.$route.params.communityId },
      });
      return {
        eventURL: getUri({ url: href }),
        title: translateTranslationTable(this.locale, this.event?.title),
        startDate: this.$moment(this.event?.startDate)
          .add(timezoneOffset, 'minutes')
          .valueOf(),
        endDate: this.$moment(endDate)
          .add(timezoneOffset, 'minutes')
          .valueOf(),
        allDay: this.event?.allDay,
        description: `${this.joinLinkExternalCalendar} ${translateTranslationTable(this.locale, this.event?.description)}`,
        guests: this.event?.attenders || [],
        location: this.event?.locations?.length ? this.event?.locations[0].name : '',
      };
    },
    googleURL() {
      return calendarURLGenerator.google(this.computedEventConfig);
    },
    outlookURL() {
      return calendarURLGenerator.outlook(this.computedEventConfig);
    },
    appleURL() {
      return calendarURLGenerator.iCalendar(this.computedEventConfig);
    },
    joinLinkExternalCalendar() {
      const room = process.env.VUE_APP_COMMUNITY_TEMPLATE_URL.replace(
        '{subdomain}', (this.$store.getters.currentCollective?.subdomain) || 'app',
      ).replace('{slug}', this.$store.getters.currentCollective?.slug);

      return this.event?.extendedProps?.joinUrl ? `<a href="${this.event?.extendedProps?.joinUrl}">Click here to join the event</a>` : `<a href="${room}/events/${this.event?.key}/room">Click here to join the meeting</a>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  height: 30px;
  width: 30px;
  max-width: 30px;
  max-height: 30px;
  vertical-align: bottom;
  &--google {
    padding: 3px;
  }
  &--img {
    opacity: 0.7;
  }
}
</style>
